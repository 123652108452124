<template>
  <header id="header" class="mx-3 mt-3 my-2">
    <div class="flex flex-row items-center justify-space-between">
      <div class="flex flex-row justify-start items-center" id="logo">
        <a :href="websitePrefix">
          <BeeMetricLogoSlogan />
          <!-- <span @click="pushRouter('Home')" style="color: #ff7227; font-size: 16px; font-weight: bold; cursor: pointer">
            {{ BRAND_NAME }}
          </span> -->
        </a>
        <svg @click="onClickMobileNoti" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" style="margin-left: -4px; transform: translateY(6px);" class="cursor-pointer">
          <path d="M6 11.25C4.60761 11.25 3.27226 10.6969 2.28769 9.71231C1.30312 8.72774 0.75 7.39239 0.75 6C0.75 4.60761 1.30312 3.27226 2.28769 2.28769C3.27226 1.30312 4.60761 0.75 6 0.75C7.39239 0.75 8.72774 1.30312 9.71231 2.28769C10.6969 3.27226 11.25 4.60761 11.25 6C11.25 7.39239 10.6969 8.72774 9.71231 9.71231C8.72774 10.6969 7.39239 11.25 6 11.25ZM6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 4.4087 11.3679 2.88258 10.2426 1.75736C9.11742 0.632141 7.5913 0 6 0C4.4087 0 2.88258 0.632141 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.88258 11.3679 4.4087 12 6 12V12Z" fill="#EA793A"/>
          <path d="M6.69749 4.941L4.97999 5.15625L4.91849 5.44125L5.25599 5.5035C5.47649 5.556 5.51999 5.6355 5.47199 5.85525L4.91849 8.45625C4.77299 9.129 4.99724 9.4455 5.52449 9.4455C5.93324 9.4455 6.40799 9.2565 6.62324 8.997L6.68924 8.685C6.53924 8.817 6.32024 8.8695 6.17474 8.8695C5.96849 8.8695 5.89349 8.72475 5.94674 8.46975L6.69749 4.941ZM6.74999 3.375C6.74999 3.57391 6.67098 3.76468 6.53032 3.90533C6.38967 4.04598 6.19891 4.125 5.99999 4.125C5.80108 4.125 5.61032 4.04598 5.46966 3.90533C5.32901 3.76468 5.24999 3.57391 5.24999 3.375C5.24999 3.17609 5.32901 2.98532 5.46966 2.84467C5.61032 2.70402 5.80108 2.625 5.99999 2.625C6.19891 2.625 6.38967 2.70402 6.53032 2.84467C6.67098 2.98532 6.74999 3.17609 6.74999 3.375Z" fill="#EA793A"/>
        </svg>
      </div>
      <span @click="onClickExpandMenu" class="cursor-pointer relative">
        <svg width="21" height="13" viewBox="0 0 21 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="21" height="1" transform="matrix(1 0 0 -1 0 1)" fill="#111111"/>
          <rect width="21" height="1" transform="matrix(1 0 0 -1 0 7)" fill="#111111"/>
          <rect width="21" height="1" transform="matrix(1 0 0 -1 0 13)" fill="#111111"/>
        </svg>
      </span>
    </div>
    <!-- <p>{{SOLOGAN}}</p> -->
    <PopupPermissionNotification v-if="showPopupPermissionNotification"></PopupPermissionNotification>
    <PopupLogin v-if="showPopupLogin"/>
    <PopupSupport v-if="showPopupSupport"/>
    <!--    <PopupContactSale v-if="showPopupContactSale"/>-->
    <PopupPricing v-if="showPopupPricing"/>
    <PopupUpgradeAdvance v-if="showPopupUpgradeAdvance"/>
    <PopupSystemError v-if="showPopupSystemError"/>
    <PopupPromotedInfo v-if="showPopupPromotedNotification"></PopupPromotedInfo>
    <PopupNotification v-if="showPopupNotification === NOTIFICATION_TYPE.empty_response_search"
                       title="Không có sản phẩm phù hợp"
                       :contents="['Dựa trên các tiêu chí trong bộ lọc phân tích,',
                                      `${BRAND_NAME} thấy không có sản phẩm nào phù hợp`,
                                      'Thay đổi bộ lọc để tìm thấy thông tin về sản phẩm'
                                    ]"
    >
    </PopupNotification>
    <PopupMobileNotification v-if="showPopupMobileNotification"/>
    <PopupNotification v-if="showPopupNotification === NOTIFICATION_TYPE.upgrade_permission"
                       title="Hãy nâng cấp tài khoản"
                       :contents="['Tài khoản của bạn cần nâng cấp để thực hiện tính năng này']"
                       ok-title="Nâng cấp tài khoản"
                       @ok="onClickPricing"
    >
    </PopupNotification>
    <PopupNotification
      v-if="showPopupNotification === NOTIFICATION_TYPE.upgrade_business_permission"
      title="Hãy nâng cấp tài khoản"
      :contents="['Tính năng dành cho gói Standard. Bạn cần nâng cấp tài khoản để thực hiện tính năng này']"
      ok-title="Nâng cấp tài khoản"
      @ok="onClickPricingBusiness"
    />
    <PopupNotification
      v-if="showPopupNotification === NOTIFICATION_TYPE.upgrade_company_permission"
      title="Hãy nâng cấp tài khoản"
      :contents="['Tính năng dành cho gói Premium trở lên. Bạn cần nâng cấp tài khoản để thực hiện tính năng này']"
      ok-title="Nâng cấp tài khoản"
      @ok="onClickPricingBusiness"
    />
    <PopupSignUpLeadForm @cancel="closePopupSignUpLeadForm" />
  </header>
</template>
<script>
import { MutationGeneral } from '@/store/modules/GeneralModule/mutation'
import PopupLogin from '@/views/login/PopupLogin'
import { isLogined, logout } from '@/service/user/UserProfileService'
import PopupSupport from '@/views/contact/PopupSupport'
import PopupContactSale from '@/views/contact/PopupContactSale'
import { getUrlGPhotoSize } from '@/helper/GPhotoHelper'
import { LogoConstants } from '@/constant/beecost/BeeCostResourceConstant'
import { websitePrefix } from '@/config/ConfigProject'
import BannerAnnouncing from '@/components/homepage/BannerAnnouncing'
import PopupPricing from '@/views/popup/PopupPricing'
import PopupUpgradeAdvance from '@/views/popup/PopupUpgradeAdvance'
import { MutationUser } from '@/store/modules/UserModule/mutation'
import PopupPermissionNotification from '@/views/popup/PopupPermissionNotification'
import {BRAND_NAME, NOTIFICATION_TYPE, SOLOGAN} from '@/constant/general/GeneralConstant'
import { setCookie } from '@/helper/CookieHelper'
import PopupSystemError from '@/views/popup/PopupSystemError'
import PopupPromotedInfo from '@/views/popup/PopupPromotedInfo'
import PopupNotification from '@/views/popup/PopupNotification'
import {mixinStoreGetterPermission} from "@/mixin/store/MixinUser";
import {MutationSidebar} from "@/store/modules/SidebarModule/mutation";
import PopupMobileNotification from "@/views/popup/PopupMobileNotification";
import PopupSignUpLeadForm from '@/views/popup/PopupSignUpLeadForm'
import BeeMetricLogoSlogan from "@/assets/logo/BeeMetricLogoSlogan";

export default {
  name: "MobileHeader",
  components: {
    PopupMobileNotification,
    PopupUpgradeAdvance,
    PopupLogin,
    PopupSupport,
    PopupContactSale,
    BannerAnnouncing,
    PopupPermissionNotification,
    PopupPricing,
    PopupSystemError,
    PopupPromotedInfo,
    PopupNotification,
    PopupSignUpLeadForm,
    BeeMetricLogoSlogan,
  },
  data() {
    return {
      urlLogo: LogoConstants.bee_metric_slogan,
      websitePrefix: websitePrefix,
      BRAND_NAME: BRAND_NAME,
      SOLOGAN: SOLOGAN,
      NOTIFICATION_TYPE: NOTIFICATION_TYPE
    }
  },
  async mounted() {
    //save cookie for fb tracking
    let fbclid = this.$route.query['fbclid']
    if (fbclid) {
      setCookie('fbclid', fbclid)
    }
    let voucher = this.$route.query['voucher']
    if (!(await isLogined())) {
      this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupLogin}`, !!voucher || this.$route.name === 'login' || this.$route.name === 'user_view')
      if (this.$route.name === 'logout') {
        this.$store.commit(`UserModule/${MutationUser.setFullName}`, null)
        this.$router.push({ name: 'Home' })
      }
      return
    }
    await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupUpgradeAdvance}`, !!voucher)
    if (this.$route.name.includes('login')) {
      this.$router.push({ name: 'Home' })
    }
  },
  methods: {
    async pushRouter(routeName) {
      if (this.$route.name !== routeName) {
        await this.$router.push({name: routeName})
      }
    },
    onClickExpandMenu() {
      this.$store.commit(`SidebarModule/${MutationSidebar.isCollapsed}`, false)
    },
    onClickMobileNoti() {
      this.showPopupMobileNotification = true
    },
    onClickPricing() {
      this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSignUpLeadForm}`, true)
      // this.$router.push({name: 'pricing'})
    },
    onClickPricingBusiness() {
      // await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupPricing}`, true)
      this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSignUpLeadForm}`, true)
      // this.$router.push({name: 'business_pricing'})
    },
    closePopupSignUpLeadForm() {
      this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSignUpLeadForm}`, false)
    },
  },

  computed: {
    promoteVoucher: {
      get() {
        return this.$store.state.UserModule.promoteVoucher
      }
    },
    selectedVoucherId: {
      get() {
        return this.$store.state.UserModule.selectedVoucherId
      },
      async set(value) {
        await this.$store.dispatch(`UserModule/${MutationUser.setSelectedVoucherId}`, value)
      }
    },
    voucherCode: {
      get() {
        return this.$store.state.UserModule.voucherCode
      }
    },
    showPopupPermissionNotification: {
      get() {
        return this.$store.state.GeneralModule.showPopupPermissionNotification
      },
      async set(value) {
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupPermissionNotification}`, value)
      }
    },
    showPopupLogin: {
      get() {
        return this.$store.state.GeneralModule.showPopupLogin
      },
      async set(value) {
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupLogin}`, value)
      }
    },
    showPopupSupport: {
      get() {
        return this.$store.state.GeneralModule.showPopupSupport
      }
    },
    showPopupContactSale: {
      get() {
        return this.$store.state.GeneralModule.showPopupContactSale
      }
    },
    showPopupUpgradeAdvance: {
      get() {
        return this.$store.state.GeneralModule.showPopupUpgradeAdvance
      }
    },
    showPopupPricing: {
      get() {
        return this.$store.state.GeneralModule.showPopupPricing
      }
    },
    showPopupSystemError: {
      get() {
        return this.$store.state.GeneralModule.showPopupSystemError
      },
      async set(value) {
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupSystemError}`, value)
      }
    },
    showPopupPromotedNotification: {
      get() {
        return this.$store.state.GeneralModule.showPopupPromotedNotification
      },
      async set(value) {
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupPromotedNotification}`, value)
      }
    },
    showPopupNotification: {
      get() {
        return this.$store.state.GeneralModule.showPopupNotification
      },
      async set(value) {
        await this.$store.commit(`GeneralModule/${MutationGeneral.setShowPopupNotification}`, value)
      }
    },
    showPopupMobileNotification: {
      get() {
        return this.$store.state.GeneralModule.showPopupMobileNotification
      },
      async set(value) {
        await this.$store.commit(`GeneralModule/${MutationGeneral.showPopupMobileNotification}`, value)
      }
    },
    showPopupSignUpLeadForm: {
      get() {
        return this.$store.state.GeneralModule.showPopupSignUpLeadForm
      }
    },
  },
  mixins: [
    mixinStoreGetterPermission
  ]
}
</script>

<style lang="scss" scoped>
.text-highlight {
  color: $--color-primary;
}

.text-note-action {
  font-size: 0.75rem;
  margin-left: 4px;
  cursor: pointer;
}
</style>
