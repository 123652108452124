<template>
  <a-modal :closable="true"
           :maskClosable="false"
           :footer="null"
           v-model="showPopupMobileNotification"
           @cancel="onClickCancel"
           :width="600">
    <div slot="title">
      <svg width="16" height="13" viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_288:2135)">
          <path d="M12.3333 11.6318C12.2926 11.6318 12.2526 11.6245 12.2133 11.6094L3.54638 8.18828C3.41763 8.13761 3.33295 8.01028 3.33295 7.86884V3.76365C3.33295 3.62189 3.41763 3.49489 3.54638 3.44421L12.2133 0.0227893C12.3167 -0.0182627 12.4304 -0.00318892 12.522 0.0603133C12.6126 0.123816 12.6667 0.229332 12.6667 0.342225V11.2893C12.6667 11.4022 12.6126 11.5077 12.522 11.5712C12.4651 11.6113 12.3998 11.6318 12.3333 11.6318ZM3.99982 7.63344L11.9998 10.7916V0.840621L3.99982 3.99874V7.63344Z" fill="#FF8844"/>
          <path d="M3.66656 8.21079H1.33344C0.598125 8.21079 0 7.59693 0 6.84228V4.78968C0 4.03503 0.598125 3.42117 1.33344 3.42117H3.66687C3.85094 3.42117 4.00031 3.57448 4.00031 3.76338V7.86858C4 8.05749 3.85063 8.21079 3.66656 8.21079ZM1.33344 4.10559C0.965312 4.10559 0.666875 4.41219 0.666875 4.78968V6.84228C0.666875 7.22009 0.965625 7.52638 1.33344 7.52638H3.33344V4.10559H1.33344Z" fill="#FF8844"/>
          <path d="M5.00006 13H3.00006C2.83412 13 2.69349 12.8749 2.67006 12.7059L2.00349 7.91663C1.97755 7.72997 2.10412 7.55678 2.28693 7.53016C2.46568 7.50675 2.63756 7.63344 2.66349 7.82106L3.28943 12.3162H4.62662L4.18131 8.24216C4.16131 8.05454 4.29256 7.88584 4.47537 7.86371C4.65193 7.84383 4.82256 7.97789 4.84412 8.16551L5.33068 12.6196C5.34131 12.7168 5.31131 12.8133 5.24787 12.8858C5.18537 12.9583 5.09537 13 5.00006 13Z" fill="#FF8844"/>
          <path d="M13.6665 4.10555C13.5593 4.10555 13.4537 4.05231 13.3893 3.95289C13.2874 3.79606 13.3287 3.58342 13.4821 3.47887L15.4821 2.11036C15.634 2.00645 15.8415 2.0475 15.944 2.20561C16.0459 2.36244 16.0046 2.57508 15.8512 2.67964L13.8512 4.04814C13.7946 4.08695 13.7306 4.10555 13.6665 4.10555Z" fill="#FF8844"/>
          <path d="M15.6665 10.2633C15.6024 10.2633 15.5383 10.2447 15.4818 10.2059L13.4818 8.83742C13.329 8.73287 13.2871 8.51991 13.389 8.3634C13.4918 8.20592 13.6996 8.16423 13.8508 8.26814L15.8508 9.63665C16.0036 9.74121 16.0455 9.95417 15.9436 10.1107C15.8793 10.2101 15.774 10.2633 15.6665 10.2633Z" fill="#FF8844"/>
          <path d="M15.6664 6.5003H13.6664C13.4823 6.5003 13.3329 6.34699 13.3329 6.15809C13.3329 5.96918 13.4823 5.81588 13.6664 5.81588H15.6664C15.8505 5.81588 15.9998 5.96918 15.9998 6.15809C15.9998 6.34699 15.8505 6.5003 15.6664 6.5003Z" fill="#FF8844"/>
        </g>
        <defs>
          <clipPath id="clip0_288:2135">
            <rect width="16" height="13" fill="white"/>
          </clipPath>
        </defs>
      </svg>
      <span class="text-lg ml-2">Lưu ý nhỏ:</span>
    </div>
    <div class="page container-bg-white">
      <div class="rows is-mobile ">
        <div class="content-container">
          <div class="content">
            <p class="main-content" :class="{'mobile-view' :isMobile}">Metric Ecom Việt phiên bản Mobile sẽ giới hạn một số tính năng trong <span class="text-primary-500">Bộ lọc nâng cao</span> như:</p>
            <ul>
              <li><i>Nhiều danh mục chuyên sâu</i></li>
              <li><i>Bộ lọc theo tham số</i></li>
            </ul>
            <p>Metric Ecom Việt khuyến khích bạn dùng trên máy tính để có được trải nghiệm tốt hơn!</p>
          </div>
          <div class="text-right mt-4">
            <a-button style="width: 100px; height: 30px; font-size: 12px" @click="onClickOk" size="large" type="primary">Đã hiểu</a-button>
          </div>
        </div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import {MutationGeneral} from "@/store/modules/GeneralModule/mutation";
import {mixinStoreGetterGlobal} from "@/mixin/store/MixinGlobal";

export default {
  name: "PopupMobileNotification",
  methods: {
    onClickOk() {
      this.showPopupMobileNotification = false
      this.$emit('ok')
    },
    onClickCancel() {
      this.$emit('cancel')
    }
  },
  computed: {
    showPopupMobileNotification: {
      get() {
        return !!this.$store.state.GeneralModule.showPopupMobileNotification
      },
      async set(value) {
        await this.$store.commit(`${MutationGeneral.MODULE_PATH}/${MutationGeneral.showPopupMobileNotification}`, value)
      }
    },
  },
  mixins: [
    mixinStoreGetterGlobal
  ]
}
</script>

<style scoped>

</style>
