<template>
  <div :style="isCollapsed ? '' : 'height: 100vh; overflow: hidden'">
    <a-layout id="bee-mobile-default-layout">
      <MobileHeader/>
      <a-layout>
        <a-layout-content>
          <slot></slot>
        </a-layout-content>
      </a-layout>
      <div class="bg-white flex flex-col justify-space-between fixed z-10 top-0 transform transition-all-300" style="height: 100vh; overflow: scroll" :class="isCollapsed ? 'translate-x-full' : ''">
        <MobileSidebarMenu class=""/>
        <BeeFooter class="bg-white" style="font-size: 10px; padding: 0; margin: 0 auto"/>
      </div>
      <BeeFooter v-show="isCollapsed" class="" style="font-size: 10px; padding: 24px 0; margin: 0 auto"/>
    </a-layout>
  </div>
</template>

<script>
import MobileHeader from "@/components/partial/header/MobileHeader";
import MobileSidebarMenu from "@/components/partial/sidebar/MobileSidebarMenu";
import BeeFooter from "@/components/partial/footer/BeeFooter";
import {mixinStoreGetterSidebar} from "@/mixin/store/MixinStoreSidebar";

export default {
  name: "MobileDefault",
  components: {
    MobileHeader,
    MobileSidebarMenu,
    BeeFooter
  },
  mixins: [
    mixinStoreGetterSidebar
  ]
}
</script>

<style scoped>

</style>
