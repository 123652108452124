import {mapGetters} from "vuex";
import {MutationSidebar} from "@/store/modules/SidebarModule/mutation";

const mixinStoreGetterSidebar = {
  computed: {
    ...mapGetters({
      isCollapsed: `${[MutationSidebar.MODULE_PATH]}/${[MutationSidebar.isCollapsed]}`,
      menuSelected: `${[MutationSidebar.MODULE_PATH]}/${[MutationSidebar.menuSelected]}`
    })
  }
}

export {
  mixinStoreGetterSidebar
}
